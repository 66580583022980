import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IUserTarget, IUserTargetsPagination } from '../models/UserTarget/UserTarget';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserTargetService {

  private url = 'userTarget';

  constructor(private http: HttpClient) { }

  /**
   * Pobranie aktualnych celów wszystkich użytkownika
   */
  getCurrentUserTargets(urlParams= ''): Observable<HttpResponse<IUserTargetsPagination>> {
    return this.http
      .get<IUserTargetsPagination>(`${this.url}/current${urlParams}`, {observe: 'response'});
  }

  /**
   * Zamknięcie miesiąca
   */
  monthlyClose(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.url}/monthlyClose`, {observe: 'response'});
  }

  /**
   * pobranie celów użytkownika
   * @param id - id użytkownika
   */
  getUserTargetsByUser(id: number): Observable<HttpResponse<IUserTarget[]>> {
    return this.http
      .get<IUserTarget[]>(`${this.url}/user/${id}`, {observe: 'response'});
  }

  /**
   * Ostatnie cele pracownika
   */
  getLastUserTargets(): Observable<HttpResponse<IUserTarget[]>> {
    return this.http.get<IUserTarget[]>(`${this.url}/lastUserTargets`, {observe: 'response'});
  }
}
