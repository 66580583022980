import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ITask } from '../models/Task/Task';
import { Observable } from 'rxjs';
import { IFullNote } from '../models/Note/Note';
import { ICalendar } from '../models/Calendar/Calendar';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private url = 'task';

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Pobranie wszystich zadań o danym id szansy sprzedaży
   * @param salesOpportunityId - id szansy sprzedaży
   */
  getAllBySalesOpportunityId(salesOpportunityId: number): Observable<HttpResponse<ITask[]>> {
    return this.http.get<ITask[]>(`${this.url}/sales-opportunity/${salesOpportunityId}`, {observe: 'response'});
  }

  /**
   * Pobranie wszystich zadań o danym id szansy sprzedaży
   * @param salesOpportunityId - id szansy sprzedaży
   */
  getAllBySalesOpportunityIdFullData(salesOpportunityId: number): Observable<HttpResponse<IFullNote[]>> {
    return this.http.get<IFullNote[]>(`${this.url}/sales-opportunity/full/${salesOpportunityId}`, {observe: 'response'});
  }

  /**
   * Utworzenie zadania
   * @param task - zadanie
   */
  save(task: ITask): Observable<HttpResponse<ITask>> {
    return this.http.post(`${this.url}`, task, {observe: 'response'});
  }

  /**
   * Aktualizacja zadania
   * @param task - zadanie
   */
  update(task: ITask): Observable<HttpResponse<ITask>> {
    return this.http.patch(`${this.url}`, task, {observe: 'response'});
  }

  /**
   * Usuwanie zadania
   * @param id task
   */
  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.url}/${id}`, {observe: 'response'});
  }

  /**
   * Zadania usera do zrobienia na dzisiaj
   */
  getUsersTasksForToday(): Observable<HttpResponse<ITask[]>> {
    return this.http.get<ITask[]>(`${this.url}/user/forToday`, {observe: 'response'});
  }

  getCalendarData(calendar: ICalendar): Observable<HttpResponse<ITask[]>> {
    return this.http.post<ITask[]>(`${this.url}/calendar`, calendar, {observe: 'response'});
  }
}
