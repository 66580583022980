import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPotential } from '../models/Potential/Potential';

@Injectable({
  providedIn: 'root'
})
export class PotentialService {

  private url = 'potential';

  constructor(private http: HttpClient) { }

  /**
   * Aktualizacja potencjału
   * @param potential
   */
  update(potential: IPotential): Observable<HttpResponse<IPotential>> {
    return this.http
      .patch<IPotential>(`${this.url}`, potential, {observe: 'response'});
  }
}
