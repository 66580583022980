import { Moment } from 'moment';

export interface IPotential {
  id?: number;
  activations?: number;
  customer_retention?: number;
  standard?: number;
  terminal?: number;
  new_regon?: number;
  date_to?: Moment;
}

export class Potential implements IPotential {
  constructor(
    public id?: number,
    public activations?: number,
    public customer_retention?: number,
    public standard?: number,
    public terminal?: number,
    public new_regon?: number,
    public date_to?: Moment,
  ) {}
}
